<template>
  <div class="footer-comp">
      <div>
          <p>Made with <a id="nuxt" href="https://nuxt.com" target="_blank">Nuxt</a>, hosted on <a id="vercel" href="https://vercel.com" target="_blank">Vercel</a>.</p>
      </div>
  </div>
</template>

<style>
@import "assets/styles/footer-comp.css";

#nuxt, #vercel {
  color: white;
}
</style>
